import React, { Component, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import routes from "./routes";
import Header from "../App/Layouts/Header/index";
import Footer from "../App/Layouts/Footer/index";
import ScrollToTop from "react-scroll-to-top";
import Sponsor from "../App/Components/SponsorPage/Sponsor";

class App extends Component {
  render() {
    const menu = routes.map((route, index) => {
      return route.component ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => <route.component {...props} />}
        />
      ) : null;
    });

    return (
      <div>
        <ScrollToTop smooth />
        <Header />
        <Suspense fallback={<div />}>
          <Switch>{menu}</Switch>
        </Suspense>
        <Footer />
      </div>
    );
  }
}

export default App;
