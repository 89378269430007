import React from "react";

const Home = React.lazy(() => import("../App/Screens/Home"));
const Cells = React.lazy(() => import("../App/Screens/Cells"));
const CellPage = React.lazy(() => import("../App/Screens/CellPage"));
const Vertical = React.lazy(() => import("../App/Screens/vertical"));
const Partners = React.lazy(() => import("../App/Components/Partners"));

const Contact = React.lazy(() => import("../App/Screens/Contact"));

const EventsAndInitiatives = React.lazy(() =>
  import("../App/Screens/Events&Initiatives")
);
const EventsAndInitiativePage = React.lazy(() =>
  import("../App/Screens/EventAndInitiativePage")
);
const Events = React.lazy(() => import("../App/Screens/Events"));
const Initiatives = React.lazy(() => import("../App/Screens/Initiatives"));

const Teams = React.lazy(() => import("../App/Screens/Teams"));

const Conclave = React.lazy(() => import("../App/Screens/Conclave"));
const ConclaveForm = React.lazy(() => import("../App/Screens/ConclaveForm"));

const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    exact: true,
    name: "Cells",
    component: Cells,
  },
  {
    path: "/vertical",
    exact: true,
    name: "vertical",
    component: Vertical,
  },
  {
    path: "/cell/:id",
    exact: true,
    name: "CellPage",
    component: CellPage,
  },
  {
    path: "/collaborations",
    exact: true,
    name: "Partners",
    component: Partners,
  },
  {
    path: "/contact",
    exact: true,
    name: "Contact",
    component: Contact,
  },
  {
    path: "/events-and-initiatives",
    exact: true,
    name: "EventsAndInitiatives",
    component: EventsAndInitiatives,
  },
  {
    path: "/events",
    exact: true,
    name: "Events",
    component: Events,
  },
  {
    path: "/initiatives",
    exact: true,
    name: "Initiatives",
    component: Initiatives,
  },
  {
    path: "/events-and-initiatives/:type/:id",
    exact: true,
    name: "EventsAndInitiativesPage",
    component: EventsAndInitiativePage,
  },
  {
    path: "/teams",
    exact: true,
    name: "Teams",
    component: Teams,
  },
  {
    path: "/conclave",
    exact: true,
    name: "Conclave",
    component: Conclave,
  },
  {
    path: "/form",
    exact: true,
    name: "ConclaveForm",
    component: ConclaveForm,
  },
];

export default routes;
