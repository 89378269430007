import React from 'react';
import SponsorWeb from './SponsorWeb';
import SponsorMob from './SponsorMob';

export default function Sponsor() {
  return (
    <div>
      <SponsorWeb />
      <SponsorMob />
    </div>
  )
}
