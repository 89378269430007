import React from 'react';
import "./SponsorWeb.css";

import image16 from '../../Assets/images/sponsor-16.png';
import image17 from '../../Assets/images/sponsor-17.png';
import image18 from '../../Assets/images/sponsor-18.png';
import image19 from '../../Assets/images/sponsor-19.png';
import image20 from '../../Assets/images/sponsor-20.png';
import image21 from '../../Assets/images/sponsor-21.png';
import image22 from '../../Assets/images/sponsor-22.png';
import image23 from '../../Assets/images/sponsor-23.png';
import image24 from '../../Assets/images/sponsor-24.png';
import image25 from '../../Assets/images/sponsor-25.png';
import image26 from '../../Assets/images/sponsor-26.jpeg';


export default function SponsorWeb() {
  return (
    <>
      <div className="sponsor-web">
        <h1>The Bonds <span id='sponsor-web-border'>we Share</span></h1>
        <div className='sponsor-web-img'>
          <div className="row-web row-1">
          <img src={image16} alt="" />
          <img src={image17} alt="" />
          <img src={image18} alt="" />
          </div>
          <div className='row-web row-2'>
          <img src={image19} alt="" />
          <img src={image20} alt="" />
          <img src={image21} alt="" />
          <img src={image22} alt="" />
          </div>
          <div className="row-web row-3">
          <img src={image23} alt="" />
          <img src={image24} alt="" />
          <img src={image25} alt="" />
          <img src={image26} alt="" />
          </div>
        </div>
      </div>
    </>
  )
}
