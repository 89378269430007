import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ArrowLeft} from "react-feather";
import SocialIcons from "./social-icons";

class SideDrawerWeb extends Component {
  render() {
    let drawerClasses = "th-sidedrawer-web";
    if (this.props.show) {
      drawerClasses = "th-sidedrawer-web open";
    }

    return (
      <div>
        <div className={drawerClasses}>
          <div className="pt-3 pt-2 pb-4 pl-4 pr-4">
            <div className="d-flex justify-content-end mb-3">
             <ArrowLeft
                size={40}
                onClick={this.props.clicked}
                className="th-theme-color mt-1 ml-2 float-right"
                style={{ cursor: "pointer" }}
             />
            </div>
            <div>
              <Link to="/home" onClick={this.props.clicked} className="th-link">
                <p className="th-sidebar-list-item mb-4">Home</p>
              </Link>
              <Link
                to="/cells"
                onClick={this.props.clicked}
                className="th-link"
              >
                <p className="th-sidebar-list-item mb-4">
                 About
                </p>
              </Link>
              <Link
                to="/events"
                onClick={this.props.clicked}
                className="th-link"
              >
                <p className="th-sidebar-list-item mb-4">Events</p>
              </Link>
              <Link
                to="/teams"
                onClick={this.props.clicked}
                className="th-link"
              >
                <p className="th-sidebar-list-item mb-4">Teams</p>
              </Link>
              <a
                href="https://socialsummit.iitr.ac.in/"
                target="blank"
                rel="noreferrer"
                onClick={this.props.clicked}
                className="th-link"
              >
                <p className="th-sidebar-list-item mb-4 side-btn change-btn-color">Social Summit</p>
              </a>
            {/* 
              <Link
                to="/contact"
                onClick={this.props.clicked}
                className="th-link"
              >
                <p className="th-sidebar-list-item mb-4">Contact Us</p>
              </Link> 
            */}
            </div>
          </div>
          {/* 
           <div className="pt-5 pb-1 pl-4 pr-4 th-sidebar-links">
            <SocialIcons width={this.props.width} />
            </div> 
          */}
        </div>
      </div>
    );
  }
}

export default SideDrawerWeb;
