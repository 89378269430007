import React, { Component } from "react";
import Logo from "../../Assets/images/nss-logo.png";
import { Menu } from "react-feather";
import { Link } from "react-router-dom";
import SideDrawer from "../SideDrawer";
import BackDrop from "../SideDrawer/Backdrop";
import { NavLink } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideDrawerOpen: false,
    };
  }

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  openSideDrawer = () => {
    this.setState({ sideDrawerOpen: !this.state.sideDrawerOpen });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: !this.state.sideDrawerOpen });
  };

  render() {
    let backdrop;
    if (this.state.sideDrawerOpen) {
      backdrop = <BackDrop click={this.openSideDrawer} />;
    }

    return (
      <div className="pr-3 pl-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center th-head-change">
            <div className="left-nav d-flex align-items-center">
            <Link to="/">
              <img src={Logo} alt="logo" className="th-header-logo" />
            </Link>
            <div className="ml-3 mr-5">
              <p className="mb-0 th-clg-name">National Service Scheme</p>
              <p className="mb-0 th-clg-name">IIT ROORKEE</p>
            </div>
            </div>
            <div className="right-nav d-flex align-items-center nav-links">
            {/* <Link to="">
              <p className="mb-0 ml-5 th-nss">About Us</p>
            </Link> */}
            <NavLink to="/home" className="test">
              <p className="mb-0 ml-3 mr-3 th-nss th-hover">Home</p>
            </NavLink>
            <NavLink to="/about" activeClassName="active" className="test">
              <p className="mb-0 ml-3 mr-3 th-nss th-hover">About</p>
            </NavLink>
            <NavLink to="/events" activeClassName="active" className="test">
              <p className="mb-0 ml-3 mr-3 th-nss th-hover">Events</p>
            </NavLink>
            <NavLink to="/teams" className="test">
              <p className="mb-0 ml-3 mr-3 th-nss th-hover" activeClassName="active" >
                Teams
              </p>
            </NavLink>
            <a
              href="https://socialsummit.iitr.ac.in/"
              target="_blank"
              rel="noreferrer" className="test"
            >
              <button className="mb-0 ml-3 mr-3 th-nss th-head-btn">Social Summit</button>
            </a>
          </div>
          </div>
          <Menu
            size={this.props.width > 992 ? 32 : 24}
            onClick={this.drawerToggleClickHandler}
            className="th-theme-color"
            style={{ cursor: "pointer"}}
          />
        </div>
        <SideDrawer
          show={this.state.sideDrawerOpen}
          clicked={this.drawerToggleClickHandler}
        />
        {backdrop}
      </div>
    );
  }
}

export default Header;
