import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ArrowLeft} from "react-feather";

import SocialIcons from "./social-icons";

class SideDrawerMob extends Component {
  render() {
    let drawerClasses = "th-sidedrawer-mob";
    if (this.props.show) {
      drawerClasses = "th-sidedrawer-mob open";
    }

    return (
      <div>
        <div className={drawerClasses}>
          <div className="pt-2 pb-4 pl-3 pr-3">
            <div className="d-flex justify-content-end mb-3">
              <ArrowLeft
                size={28}
                onClick={this.props.clicked}
                className="th-theme-color mt-1 ml-2 float-right"
                style={{ cursor: "pointer" }}
              />
            </div>
            <div>
              <Link to="/home" onClick={this.props.clicked} className="th-link">
                <p className="th-sidebar-list-item mb-3">Home</p>
              </Link>
              <Link
                to="/about"
                onClick={this.props.clicked}
                className="th-link"
              >
                <p className="th-sidebar-list-item mb-3">
                  About
                </p>
              </Link>
              <Link
                to="/events"
                onClick={this.props.clicked}
                className="th-link"
              >
                <p className="th-sidebar-list-item mb-3">Events</p>
              </Link>
              <Link
                to="/teams"
                onClick={this.props.clicked}
                className="th-link"
              >
                <p className="th-sidebar-list-item mb-3">Teams</p>
              </Link>
              <a
                href="https://socialsummit.iitr.ac.in/"
                target="blank"
                rel="noreferrer"
                onClick={this.props.clicked}
              >
                <p className="th-sidebar-list-item mb-4 side-btn change-btn-color">Social Summit</p>
              </a>
            </div>
          </div>
             {/* <div className="pl-3 pr-3 th-sidebar-links">
               <SocialIcons width={this.props.width} />
             </div> */}
        </div>
      </div>
    );
  }
}

export default SideDrawerMob;
